import React from 'react';
import { Link } from 'react-router-dom';
import Header from "../component/Header";
import { Row, Col, BackTop, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import kvimg from "../images/news_bg.jpg";

export default function ChargingStationPolicy() {    
    const { t } = useTranslation();

    return (
        <>
            <Header/>
            <div className='page-title-bg' style={{backgroundImage:`url(${kvimg})`}} id='pagetitle'>
                {t('charging-station-policy.subtitle') ? <p className='page-title-sub'>{t('charging-station-policy.subtitle')}</p> : ''}  
                <h1>Morbito APP Terms & Privacy</h1>                
            </div>  
            <div className='breadcrumb'>
                <div className='container-s'>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Morbito APP Terms & Privacy
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>   
            </div>
            <section className='page-news-page-section'>
                <div className="container-s">
                    <div className="news-page-box">  
                       <Row gutter={[64, 48]}>
                            <Col lg={8} md={8} sm={24} xs={24}>
                                <div className='news-page-box-title'>                                   
                                    <h1>{t('charging-station-policy.terms.title')}</h1>
                                    <div className="publish">{t('charging-station-policy.terms.subtitle')}</div>
                                    <div className="date">{t('charging-station-policy.terms.date')}</div>                            
                                </div>
                            </Col>
                            <Col lg={16} md={16} sm={24} xs={24}>
                                <div className="news-page-box-content">
                                    <p style={{marginBottom: '3rem'}}>{t('charging-station-policy.terms.description')}</p>
                                    <h3>{t('charging-station-policy.terms.subtitle1')}</h3>
                                    <p>{t('charging-station-policy.terms.subtitle1-description')}</p>
                                    <ol>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle1-list-1-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle1-list-1-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle1-list-2-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle1-list-2-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle1-list-3-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle1-list-3-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle1-list-4-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle1-list-4-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle1-list-5-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle1-list-5-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle1-list-6-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle1-list-6-content')}
                                        </li>
                                    </ol>
                                    <h3>{t('charging-station-policy.terms.subtitle2')}</h3>
                                    <ol>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle2-list-1-title')}</strong>- 
                                            {t('charging-station-policy.terms.subtitle2-list-1-content')}
                                            <ul>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-1-content-list1')}</li>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-1-content-list2')}</li>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-1-content-list3')}</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle2-list-2-title')}</strong>- 
                                            {t('charging-station-policy.terms.subtitle2-list-2-content')}
                                            <ul>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-2-content-list1')}</li>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-2-content-list2')}</li>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-2-content-list3')}</li>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-2-content-list4')}</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle2-list-3-title')}</strong>- 
                                            {t('charging-station-policy.terms.subtitle2-list-3-content')}
                                            <ul>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-3-content-list1')}</li>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-3-content-list2')}</li>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-3-content-list3')}</li>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-3-content-list4')}</li>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-3-content-list5')}</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle2-list-4-title')}</strong>- 
                                            {t('charging-station-policy.terms.subtitle2-list-4-content')}
                                            <ul>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-4-content-list1')}</li>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-4-content-list2')}</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle2-list-5-title')}</strong>- 
                                            {t('charging-station-policy.terms.subtitle2-list-5-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle2-list-6-title')}</strong>- 
                                            {t('charging-station-policy.terms.subtitle2-list-6-content')}
                                            <ul>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-6-content-list1')}</li>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-6-content-list2')}</li>
                                                <li>{t('charging-station-policy.terms.subtitle2-list-6-content-list3')}</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle2-list-7-title')}</strong> - 
                                            {t('charging-station-policy.terms.subtitle2-list-7-content')}
                                        </li>
                                    </ol>
                                    <h3>{t('charging-station-policy.terms.subtitle3')}</h3>
                                    <ol>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle3-list-1-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle3-list-1-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle3-list-2-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle3-list-2-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle3-list-3-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle3-list-3-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle3-list-4-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle3-list-4-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle3-list-5-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle3-list-5-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle3-list-6-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle3-list-6-content')}
                                        </li>
                                    </ol>
                                    <h3>{t('charging-station-policy.terms.subtitle4')}</h3>
                                    <ol>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle4-list-1-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle4-list-1-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle4-list-2-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle4-list-2-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle4-list-3-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle4-list-3-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle4-list-4-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle4-list-4-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle4-list-5-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle4-list-5-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle4-list-6-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle4-list-6-content')}
                                        </li>
                                    </ol>
                                    <h3>{t('charging-station-policy.terms.subtitle5')}</h3>
                                    <ol>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle5-list-1-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle5-list-1-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle5-list-2-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle5-list-2-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle5-list-3-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle5-list-3-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle5-list-4-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle5-list-4-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle5-list-5-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle5-list-5-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle5-list-6-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle5-list-6-content')}
                                        </li>
                                    </ol>
                                    <h3>{t('charging-station-policy.terms.subtitle6')}</h3>
                                    <ol>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle6-list-1-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle6-list-1-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle6-list-2-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle6-list-2-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle6-list-3-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle6-list-3-content')}
                                        </li>
                                    </ol>
                                    <p>{t('charging-station-policy.terms.subtitle6-description')}</p>
                                    <h3>{t('charging-station-policy.terms.subtitle7')}</h3>
                                    <ol>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle7-list-1-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle7-list-1-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle7-list-2-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle7-list-2-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle7-list-3-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle7-list-3-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle7-list-4-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle7-list-4-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle7-list-5-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle7-list-5-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.terms.subtitle7-list-6-title')}</strong><br/>
                                            {t('charging-station-policy.terms.subtitle7-list-6-content')}
                                        </li>
                                    </ol>
                                    <h3>{t('charging-station-policy.terms.subtitle8')}</h3>
                                    <p>{t('charging-station-policy.terms.subtitle8-description')}</p>
                                    <h3>{t('charging-station-policy.terms.subtitle9')}</h3>
                                    <p>{t('charging-station-policy.terms.subtitle9-description')}</p>
                                    <h3>{t('charging-station-policy.terms.subtitle10')}</h3>
                                    <p>{t('charging-station-policy.terms.subtitle10-description')}</p>
                                    <h3>{t('charging-station-policy.terms.subtitle11')}</h3>
                                    <p>{t('charging-station-policy.terms.subtitle11-description')}</p>
                                    <h3>{t('charging-station-policy.terms.subtitle12')}</h3>
                                    <p>{t('charging-station-policy.terms.subtitle12-description')}</p>
                                    <h3>{t('charging-station-policy.terms.subtitle13')}</h3>
                                    <p>{t('charging-station-policy.terms.subtitle13-description')}</p>
                                    <hr/>
                                    <p>{t('charging-station-policy.terms.any-question')}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="news-page-box">  
                        <Row gutter={[64, 48]}>
                            <Col lg={8} md={8} sm={24} xs={24}>
                                <div className='news-page-box-title'>                                   
                                    <h1>{t('charging-station-policy.privacy.title')}</h1>
                                    <div className="publish">{t('charging-station-policy.privacy.subtitle')}</div>
                                    <div className="date">{t('charging-station-policy.privacy.date')}</div>                            
                                </div>
                            </Col>
                            <Col lg={16} md={16} sm={24} xs={24}>
                                <div className="news-page-box-content">
                                    <p style={{marginBottom: '3rem'}}>{t('charging-station-policy.privacy.description')}</p>
                                    <h3>{t('charging-station-policy.privacy.subtitle1')}</h3>
                                    <p>{t('charging-station-policy.privacy.subtitle1-description')}</p>
                                    <ul>
                                        <li>{t('charging-station-policy.privacy.subtitle1-list-1')}</li>
                                        <li>{t('charging-station-policy.privacy.subtitle1-list-2')}</li>
                                        <li>{t('charging-station-policy.privacy.subtitle1-list-3')}</li>
                                        <li>{t('charging-station-policy.privacy.subtitle1-list-4')}</li>
                                    </ul>
                                    <h3>{t('charging-station-policy.privacy.subtitle2')}</h3>
                                    <h4>{t('charging-station-policy.privacy.subtitle2-list1-title')}</h4>
                                    <ul>
                                        <li>{t('charging-station-policy.privacy.subtitle2-list1-1')}</li>
                                        <li>{t('charging-station-policy.privacy.subtitle2-list1-2')}</li>
                                        <li>{t('charging-station-policy.privacy.subtitle2-list1-3')}</li>
                                    </ul>
                                    <h4>{t('charging-station-policy.privacy.subtitle2-list2-title')}</h4>
                                    <ul>
                                        <li>{t('charging-station-policy.privacy.subtitle2-list2-1')}</li>
                                        <li>{t('charging-station-policy.privacy.subtitle2-list2-2')}</li>
                                        <li>{t('charging-station-policy.privacy.subtitle2-list2-3')}</li>
                                    </ul>
                                    <h3>{t('charging-station-policy.privacy.subtitle3')}</h3>
                                    <p>{t('charging-station-policy.privacy.subtitle3-description')}</p>
                                    <ul>
                                        <li>
                                            <strong>{t('charging-station-policy.privacy.subtitle3-list1-title')}</strong>
                                            {t('charging-station-policy.privacy.subtitle3-list1-content')}
                                        </li>
                                        <li>
                                            <strong>{t('charging-station-policy.privacy.subtitle3-list2-title')}</strong>
                                            {t('charging-station-policy.privacy.subtitle3-list2-content')}
                                            <ul>
                                                <li>{t('charging-station-policy.privacy.subtitle3-list2-1')}</li>
                                                <li>{t('charging-station-policy.privacy.subtitle3-list2-2')}</li>
                                                <li>{t('charging-station-policy.privacy.subtitle3-list2-3')}</li>
                                                <li>{t('charging-station-policy.privacy.subtitle3-list2-4')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <h3>{t('charging-station-policy.privacy.subtitle4')}</h3>
                                    <p>{t('charging-station-policy.privacy.subtitle4-description')}</p>
                                    <ol>
                                        <li>{t('charging-station-policy.privacy.subtitle4-list1')}</li>
                                        <li>{t('charging-station-policy.privacy.subtitle4-list2')}</li>
                                        <li>{t('charging-station-policy.privacy.subtitle4-list3')}</li>
                                    </ol>
                                    <h3>{t('charging-station-policy.privacy.subtitle5')}</h3>
                                    <p>{t('charging-station-policy.privacy.subtitle5-description')}</p>
                                    <h3>{t('charging-station-policy.privacy.subtitle6')}</h3>
                                    <p>{t('charging-station-policy.privacy.subtitle6-description')}</p>
                                </div>
                            </Col>
                        </Row> 
                    </div>
                </div>
            </section>
            <BackTop />
        </>
        
    );
    
}