import React from 'react';
import Home from "./pages/Home";
import About from "./pages/About";
import News from "./pages/News";
import NewsPage from "./pages/News_Page";
import Solutions from "./pages/Solutions";
import Contact from "./pages/Contact";
import Project from "./pages/Project";
import NotFound from "./pages/NotFound";
import Footer from "./component/Footer";
import ChargingStationPolicy from "./pages/ChargingStationPolicy";
import { Routes, Route , useLocation } from "react-router-dom";
import { CSSTransition,  TransitionGroup } from "react-transition-group";
import "./App.less";


function App() {
  const location = useLocation();

  return ( 
    <>      
      <TransitionGroup className="page-scroll">
        <CSSTransition key={location.key} timeout={500} classNames="page-transition">       
          <Routes location={location}>
              <Route path="/news" element={<News />} />
              <Route path="/news/:id" element={<NewsPage />} />
              <Route path="/about" element={<About />} />  
              <Route path="/contact" element={<Contact/>} />
              <Route path="/solutions" element={<Solutions/>} />
              <Route path="/project" element={<Project/>} />
              <Route path="/" element={<Home />} /> 
              <Route path="/news/*" element={<NotFound />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/*" element={<NotFound />} />
              <Route path="/morbito-app-policy" element={<ChargingStationPolicy/>} />
          </Routes>      
        </CSSTransition>
      </TransitionGroup>
      <Footer/>  
    </>
      
  );
}

export default App;